@if (showHeader) {
  <img
    class="header"
    [src]="(theme$ | async)?.spinMachineHeaderAsset?.url"
    alt="Header Asset" />
}
<div class="machine-container">
  <img
    class="background"
    [src]="(theme$ | async)?.spinMachineBackgroundAsset?.url"
    alt="Background Asset" />
  <div class="reels">
    @for (reel of reels; track reel.index; let idx = $index) {
      <div class="reel">
        @for (icon of reel.icons; let iconIdx = $index; track trackIconFn(iconIdx, icon); ) {
          <div
            class="reel-icon-container icon-container-{{ idx }}"
            [class.spin]="spin$ | async">
            <img
              class="reel-icon icon-{{ iconIdx }}"
              width="80%"
              height="80%"
              [src]="icon.url"
              alt="Icon" />
          </div>
        }
      </div>
    }
  </div>
</div>
@if (isGBCountry$ | async) {
  <span class="machine-info-label">{{ 'spinMachine.label' | translate }}</span>
}
