import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import {
  BackgroundAnimationType,
  ConfigValue,
  Asset,
  CampaignType,
  CampaignTriggerType,
  GameCampaign,
} from '@longnecktech/splash-commons-fe';
import { SessionService } from './session.service';
import { Game } from '@shared/types/game';
import { Theme } from '@shared/types/theme';
import { tap, take, map } from 'rxjs/operators';
import { SpinService } from './spin.service';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  constructor(
    public readonly http: HttpClient,
    public readonly spinService: SpinService,
    public readonly session: SessionService,
  ) {}

  public fetchGame(withSpinInfo = false) {
    return this.http
      .get<Game>(
        `${environment.backendUrl}/api/spinmachine/game?${this.getQueryParams()}`,
      )
      .pipe(
        map((data) => ({
          ...data,
          campaign: {
            ...data.campaign,
            slides: [
              {
                uuid: '1',
                mobileImage: {
                  uuid: 'c79617e6-5af0-4cd9-b8d3-0bbf7e93ac49',
                  url: 'https://fsm-assets-dev.splash.tech/fsm-dev/a622893c-a4ab-4b44-8636-fa4dfb826c72_1p1681080x16801.png',
                  label: 'Desktop lobby',
                } as Asset,
                desktopImage: {
                  uuid: '590534fa-a32d-4ac9-a30e-d8e65cfadf34',
                  url: 'https://fsm-assets-dev.splash.tech/fsm-dev/a622893c-a4ab-4b44-8636-fa4dfb826c72_1p1681080x16801.png',
                  label: 'Mobile lobby',
                } as Asset,
                link: 'https://www.figma.com/design/ZHwYJbYo5jIuEtpPdQS3wv/%5BConcept%5D-Jacks-Lobby?node-id=3846-5471&node-type=rounded_rectangle&m=dev',
                campaignType: CampaignType.Image,
                triggerType: CampaignTriggerType.Button,
                ctaButtonLabel: {
                  enValue: 'Press this button',
                },
              },
              {
                uuid: '2',
                campaignType: CampaignType.Html,
                link: 'https://www.figma.com/design/ZHwYJbYo5jIuEtpPdQS3wv/%5BConcept%5D-Jacks-Lobby?node-id=3846-5471&node-type=rounded_rectangle&m=dev',
                html: '<ul>&#10;  <li>Milk</li>&#10;  <li>&#10;    Cheese&#10;    <ul>&#10;      <li>Blue cheese</li>&#10;      <li>Feta</li>&#10;    </ul>&#10;  </li>&#10;</ul>&#10;&#10;<img src="https://png.pngtree.com/png-vector/20191126/ourmid/pngtree-image-of-cute-radish-vector-or-color-illustration-png-image_2040180.jpg">',
                triggerType: CampaignTriggerType.Button,
                ctaButtonLabel: {
                  enValue: 'Press this button',
                },
              },
            ],
          } as GameCampaign,
        })),
        tap({
          next: (data) => {
            this.session.setGame(data);
            this.injectCSSVariablesIntoDOM(data.theme);
            if (withSpinInfo) {
              this.spinService
                .fetchSpinInfo(data)
                .pipe(take(1))
                .subscribe({
                  next: (nextSpinInfo) => {
                    if (nextSpinInfo.nextSpinInSeconds > 0) {
                      this.spinService.updateShowNextSpinInfo(true);
                    }
                  },
                });
            }
          },
          error: () => {
            // TODO: display no game found
          },
        }),
      );
  }

  private hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }

  private injectCSSVariablesIntoDOM(theme: Theme) {
    const styleEl = document.createElement('style');
    const fonts = `
      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontRegularAsset?.url
            ? theme.primaryFontRegularAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Regular.ttf'
        }') format('truetype');
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontBoldAsset?.url
            ? theme.primaryFontBoldAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Bold.ttf'
        }') format('truetype');
        font-weight: 700;
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontMediumAsset?.url
            ? theme.primaryFontMediumAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Medium.ttf'
        }') format('truetype');
        font-weight: 500;
      }
    `;
    const animationVariables = this.getAnimationVariables(
      theme.backgroundAnimation,
    );
    styleEl.textContent = `:root {
      ${theme.cssVariables
        .filter((v) => v.key.includes('--') && v.value)
        .map((v: ConfigValue) => {
          const originalValue = v.value;
          let rgbValue;

          if (originalValue.startsWith('rgba')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = `${match[0]}, ${match[1]}, ${match[2]}`;
            }
          } else if (originalValue.startsWith('rgb')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = match.join(', ');
            }
          } else if (originalValue.startsWith('#')) {
            rgbValue = this.hexToRgb(originalValue);
          }

          return `
          ${v.key}: ${originalValue};
          ${v.key}-rgb: ${rgbValue};
        `;
        })
        .join('\n')}
        ${animationVariables}
    }
    ${fonts}
    `;
    document.head.appendChild(styleEl);
  }

  private getQueryParams(): string {
    const params: { instance?: string; gameUuid?: string } = {};
    if (this.session.instance) params['instance'] = this.session.instance;
    if (this.session.gameUuid) params['gameUuid'] = this.session.gameUuid;
    return new URLSearchParams(params).toString();
  }

  private getAnimationVariables(
    bgAnimationType: BackgroundAnimationType | null,
  ): string {
    if (!bgAnimationType) return '';
    if (bgAnimationType === BackgroundAnimationType.SNOW) {
      return '--particle-color: #ffffff';
    }
    if (bgAnimationType === BackgroundAnimationType.SPARKLE) {
      return '--particle-color: #f4b300';
    }
    return '';
  }
}
