<div
  class="carousel-container"
  [class.mobile]="isMobilePage"
  #carouselContainer>
  <div class="carousel flex" #carousel>
    @for (item of data;
      track item.assetUrl;
      let i = $index) {
      <div
        class="game-block flex flex-column flex-align-center flex-justify-center"
        tabindex="0"
        [class.active]="i === currentIndex"
        (keyup)="changeActiveItem(i - currentIndex)"
        (click)="changeActiveItem(i - currentIndex)">
        <img
          [src]="item.assetUrl"
          [alt]="item.name || 'Image'"
          class="game-image"/>
        @if (item.name) {
          <span class="game-name text-medium text-center">{{ item.name }}</span>
        }
      </div>
    }
  </div>
</div>
<div class="footer-container flex flex-justify-center">
  <button class="action-button play-now-btn"
          [class]="isMobilePage ? 'med': 'maxi'"
          (click)="onNavigateToGame()">
    {{ 'winnings.playNow' | translate }}
  </button>
</div>
