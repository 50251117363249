export var GameType;
(function (GameType) {
  GameType["FreeToPlay"] = "F2P";
  GameType["PayToPlay"] = "P2P";
})(GameType || (GameType = {}));
export var SubmissionUpdateMode;
(function (SubmissionUpdateMode) {
  SubmissionUpdateMode["ALLOWED"] = "ALLOWED";
  SubmissionUpdateMode["DISALLOWED"] = "DISALLOWED";
})(SubmissionUpdateMode || (SubmissionUpdateMode = {}));
export var QCardFormat;
(function (QCardFormat) {
  QCardFormat["Cards"] = "CARDS";
  QCardFormat["Long"] = "LONG";
})(QCardFormat || (QCardFormat = {}));
export var QuizGameHeaderTabs;
(function (QuizGameHeaderTabs) {
  QuizGameHeaderTabs["PLAY"] = "PLAY";
  QuizGameHeaderTabs["RESULTS"] = "RESULTS";
  QuizGameHeaderTabs["WINNERS"] = "WINNERS";
  QuizGameHeaderTabs["LEADERBOARDS"] = "LEADERBOARDS";
})(QuizGameHeaderTabs || (QuizGameHeaderTabs = {}));