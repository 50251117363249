import { Asset } from '@longnecktech/splash-commons-fe';

export enum CollectibleGoalType {
  COLLECTIBLE = 'COLLECTIBLE',
  INSTANT_WIN = 'INSTANT_WIN',
  TWO_OF_A_KIND = 'TWO_OF_A_KIND',
  THREE_OF_A_KIND = 'THREE_OF_A_KIND',
}

export interface CollectibleIcon {
  // transient
  index: number;
  collected: boolean;
  animate: boolean;
}

export interface CollectibleGoal {
  goal: Goal;
  count: number;
  inThisSpinCollectedCount: number;
  icons?: CollectibleIcon[];
}

export interface Goal {
  uuid: string;
  icon: Asset;
  type: CollectibleGoalType;
  target: number;
  prizeNameShort: string;
  prizeName: string;
  prizeDesc: string;
  freeSpinsAmount: number;
  claimLink?: string;
  claimAsset?: Asset;
  assetLabel: string;
  index: number;
}
