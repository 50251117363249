import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Pipe({
  name: 'translate',
  standalone: true,
})
export class TranslationPipe implements PipeTransform {
  constructor(private translate: TranslationService) {}

  transform(value: string, args: string[] = []): string {
    if (args && args.length > 0) {
      return this.translate.translateArgs(value, ...args);
    } else {
      return this.translate.translate(value);
    }
  }
}
