import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GameCarouselComponent } from '@shared/components/game-carousel/game-carousel.component';

import { TabsComponent } from './components/tabs/tabs.component';
import { InfoComponent } from './components/info/info.component';
import { FooterComponent } from './components/footer/footer.component';
import { WinningsComponent } from './components/winnings/winnings.component';
import { SpinmachineComponent } from './components/spinmachine/spinmachine.component';
import { NextSpinInfoComponent } from './components/next-spin-info/next-spin-info.component';
import { LottieMachineComponent } from './components/lottiemachine/lottie-machine.component';
import { CharityComponent } from './components/charity/charity.component';
import { TranslationPipe } from './pipes/translation.pipe';
import { LottieComponent } from 'ngx-lottie';

@NgModule({
  declarations: [
    TabsComponent,
    InfoComponent,
    FooterComponent,
    WinningsComponent,
    SpinmachineComponent,
    NextSpinInfoComponent,
    LottieMachineComponent,
    CharityComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    LottieComponent,
    TranslationPipe,
    GameCarouselComponent,
  ],
  exports: [
    TabsComponent,
    InfoComponent,
    FooterComponent,
    WinningsComponent,
    SpinmachineComponent,
    NextSpinInfoComponent,
    LottieMachineComponent,
    TranslationPipe,
  ],
})
export class SharedModule {}
