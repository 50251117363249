import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from './session.service';
import { tap, switchMap, filter } from 'rxjs/operators';
import {
  CollectibleGoal,
  CollectibleIcon,
  CollectibleGoalType,
} from '@shared/types/collectible-goal';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollectiblesService {
  private _collectibles = new BehaviorSubject<CollectibleGoal[]>([]);
  private _needAnimationOnSpin = new BehaviorSubject<boolean | null>(null);

  collectibles$ = this._collectibles.asObservable();
  needAnimationOnSpin$ = this._needAnimationOnSpin
    .asObservable()
    .pipe(filter((needAnimationOnSpin) => needAnimationOnSpin !== null));

  constructor(
    private http: HttpClient,
    private session: SessionService,
  ) {}

  fetchCollectibles(): Observable<CollectibleGoal[]> {
    return this.session.game$.pipe(
      switchMap((game) =>
        this.http.get<CollectibleGoal[]>(
          environment.backendUrl + '/api/spinmachine/collectible/' + game!.uuid,
        ),
      ),
      tap((data) => {
        if (!data) {
          // TODO: display no collectibles found
        } else {
          this.updateCollectibles(data);
        }
      }),
    );
  }

  updateCollectibles(data: CollectibleGoal[], isFromSpin = false): void {
    const collectibles = this.getUpdatedCollectibles(data, isFromSpin);
    this._collectibles.next(collectibles);
    // check if we need to animate collectibles on spin action
    // based on this property we can start animation and/or submit
    // winner
    if (isFromSpin) {
      const needToAnimate = collectibles
        .filter((col) => col.goal.type === CollectibleGoalType.COLLECTIBLE)
        .some((col) => (col.icons || []).find((icon) => icon.animate));
      this._needAnimationOnSpin.next(needToAnimate);
    }
  }

  resetNeedAnimationFlag(): void {
    this._needAnimationOnSpin.next(null);
  }

  private getUpdatedCollectibles(
    data: CollectibleGoal[],
    isFromSpin: boolean,
  ): CollectibleGoal[] {
    return data
      .reduce((acc: CollectibleGoal[], collectible: CollectibleGoal) => {
        const existing = this._collectibles.value.find(
          (c) => c.goal.uuid === collectible.goal.uuid,
        );
        if (existing) {
          acc.push({
            ...existing,
            count: collectible.count,
            inThisSpinCollectedCount: collectible.inThisSpinCollectedCount,
            icons: this.getIcons(collectible, isFromSpin),
          });
        } else {
          acc.push({
            ...collectible,
            icons: this.getIcons(collectible, isFromSpin),
          });
        }
        return acc;
      }, [])
      .sort((a, b) => a.goal.index - b.goal.index);
  }

  private getIcons(
    col: CollectibleGoal,
    isFromSpin: boolean,
  ): CollectibleIcon[] {
    const icons = [];
    for (let i = 0; i < col.goal.target; i++) {
      icons.push({
        index: i,
        collected: i < col.count,
        animate: isFromSpin
          ? i < col.count && i >= col.count - col.inThisSpinCollectedCount
          : false,
      });
    }
    return icons;
  }
}
